import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
    return (
        <div className="custom-select-option">
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const displayNames = JSON.parse(localStorage.getItem("displayNames"));


const CompActiveHeirarchyAndCampaignFilter = (props) => {
    const divRef = useRef(null)
    const [DivHeight, setDivHeight] = useState("");

    useEffect(() => {
        if (divRef.current !== null) {
            setDivHeight(divRef.current.clientHeight / 2);
        }
    }, [props]);

    const handleFilterClick = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            props.setFilterTabShow(false)
        }
    }

    return (

        <div className="col-xl-12" onClick={(e) => {
            handleFilterClick(e)
        }}>
            <div className="card-body filter-bg p-0" style={{}}>
                <div className="card custom-summary filter-active" ref={divRef} style={{}}>
                    <div className="card-body p-0" style={{display: "flex", flexDirection: "column", }}>
                    {/* height: DivHeight + "px", */}
                    { props.ActiveFilters?.length > 0 && <div className='active-first-half' style={{ height: DivHeight +  "px", overflow: "scroll", flex: 1 }}> 
                            <div className="custom-header mb-1" style={{position: "sticky", top: 0, zIndex: 9999999, backgroundColor: "#1c2427", borderRadius: "15px 15px 0 0"}}>
                                <span className="header-title">
                                    <h3 className="data-spends my-2 px-2">Analytics Filter</h3>
                                    <h3 className="m-0" onClick={() => props.setFilterTabShow(false)}><i className="fa fa-times cursor-pointer" aria-hidden="true"></i> &nbsp;</h3>
                                </span>
                            </div>
                            <div className="custom-bod" style={{height: "100%", margin: "0 1rem 0 1rem"}}>
                                <DragDropContext onDragEnd={props.onHeirarchyDragEnd}>
                                    <div className="row">
                                        <div className="col-sm-12" >
                                            <div className="p-0" >
                                                <div className="row">
                                                    <Droppable droppableId={'active'}>
                                                        {(provided) => (
                                                            <div className="col-sm-12" {...provided.droppableProps} ref={provided.innerRef}>
                                                                {
                                                                    props.ActiveFilters.map((item, index) => {
                                                                        return (
                                                                            <Draggable key={item.name} draggableId={item.name} index={index}>
                                                                                {(provided) => (
                                                                                    <span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="btn mb-3 cm-drop-btn">
                                                                                        <div className="drop-header">
                                                                                            <p className="m-0 text-left">{displayNames[item.name]}</p>
                                                                                            {/* <p className="m-0"><i className="fa fa-minus" aria-hidden="true"></i></p> */}
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6 border-right-2x">
                                                                                                <div className="drop-body text-left">
                                                                                                    <label className="m-0" htmlFor="chart1"> Chart 1</label>
                                                                                                    <ReactSelect
                                                                                                        options={item.filters}
                                                                                                        isMulti
                                                                                                        closeMenuOnSelect={false}
                                                                                                        hideSelectedOptions={false}
                                                                                                        components={{
                                                                                                            Option
                                                                                                        }}
                                                                                                        onChange={(selected) => {
                                                                                                            if (selected !== null && selected.length > 0) {
                                                                                                                if (selected[selected.length - 1].value === 'all') {
                                                                                                                    return props.onChangeHeirarchyFilter(selected[selected.length - 1], index, "Selected1", [])
                                                                                                                } else {
                                                                                                                    for (var i = 0; i < selected.length; i++) {
                                                                                                                        if (selected[i].value === 'all') {
                                                                                                                            selected.splice(i, 1)
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                            return props.onChangeHeirarchyFilter(selected, index, "Selected1", [])
                                                                                                        }}
                                                                                                        allowSelectAll={true}
                                                                                                        value={item.Selected1 ? item.Selected1 : null}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-6">
                                                                                                <div className="drop-body text-left">
                                                                                                    <label className="m-0" htmlFor="chart2"> Chart 2</label>
                                                                                                    <ReactSelect
                                                                                                        options={item.filters}
                                                                                                        isMulti
                                                                                                        closeMenuOnSelect={false}
                                                                                                        hideSelectedOptions={false}
                                                                                                        components={{
                                                                                                            Option
                                                                                                        }}
                                                                                                        onChange={(selected) => {
                                                                                                            if (selected !== null && selected.length > 0) {
                                                                                                                if (selected[selected.length - 1].value === 'all') {
                                                                                                                    return props.onChangeHeirarchyFilter(selected[selected.length - 1], index, "Selected2", [])
                                                                                                                } else {
                                                                                                                    for (var i = 0; i < selected.length; i++) {
                                                                                                                        if (selected[i].value === 'all') {
                                                                                                                            selected.splice(i, 1)
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                            return props.onChangeHeirarchyFilter(selected, index, "Selected2", [])
                                                                                                        }}
                                                                                                        allowSelectAll={true}
                                                                                                        value={item.Selected2 ? item.Selected2 : null}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DragDropContext>
                            </div>
                        </div>}
                        {/* height: DivHeight + "px", */}
                        <div className='active-second-half' style={{  overflow: "scroll", flex: 1 }}>
                            <div className="custom-header mb-1 b-t-dark-1" style={{position: "sticky", top: 0, zIndex: 9999999, backgroundColor: "#1c2427"}}>
                                <span className="header-title">
                                    <h3 className="data-spends my-2 px-2">Channel Filter</h3>
                                </span>
                            </div>

                            <div className="custom-bod" style={{ height: "100%", margin: "0 1rem 0 1rem"}}>
                                <DragDropContext onDragEnd={props.onChannelDragEnd}>
                                    <div className="row">
                                        <div className="col-sm-12" >
                                            <div className="p-0" >
                                                <div className="row">
                                                    <Droppable droppableId={'active'}>
                                                        {(provided) => (
                                                            <div className="col-sm-12" {...provided.droppableProps} ref={provided.innerRef}>
                                                                {
                                                                    props.CompActiveFilters.map((item, index) => {
                                                                        return (
                                                                            <Draggable key={item.name} draggableId={item.name} index={index}>
                                                                                {(provided) => (
                                                                                    <span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="btn mb-3 cm-drop-btn">
                                                                                        <div className="drop-header">
                                                                                            <p className="m-0 text-left">{displayNames[item.name]}</p>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6 border-right-2x">
                                                                                                <div className="drop-body text-left">
                                                                                                    <label className="m-0" htmlFor="chart1"> Chart 1</label>
                                                                                                    <ReactSelect
                                                                                                        options={item.filters}
                                                                                                        isMulti
                                                                                                        closeMenuOnSelect={false}
                                                                                                        hideSelectedOptions={false}
                                                                                                        components={{
                                                                                                            Option
                                                                                                        }}
                                                                                                        onChange={(selected) => {
                                                                                                            if (selected !== null && selected.length > 0) {
                                                                                                                if (selected[selected.length - 1].value === 'All') {
                                                                                                                    return props.onChannelChangeFilter(selected[selected.length - 1], index, 'Selected1')
                                                                                                                } else {
                                                                                                                    for (var i = 0; i < selected.length; i++) {
                                                                                                                        if (selected[i].value === 'All') {
                                                                                                                            selected.splice(i, 1)
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                            return props.onChannelChangeFilter(selected, index, 'Selected1')
                                                                                                        }}
                                                                                                        allowSelectAll={true}
                                                                                                        value={item.Selected1 ? item.Selected1 : null}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-6">
                                                                                                <div className="drop-body text-left">
                                                                                                    <label className="m-0" htmlFor="chart2"> Chart 2</label>
                                                                                                    <ReactSelect
                                                                                                        options={item.filters}
                                                                                                        isMulti
                                                                                                        closeMenuOnSelect={false}
                                                                                                        hideSelectedOptions={false}
                                                                                                        components={{
                                                                                                            Option
                                                                                                        }}
                                                                                                        onChange={(selected) => {
                                                                                                            if (selected !== null && selected.length > 0) {
                                                                                                                if (selected[selected.length - 1].value === 'All') {
                                                                                                                    return props.onChannelChangeFilter(selected[selected.length - 1], index, 'Selected2')
                                                                                                                } else {
                                                                                                                    for (var i = 0; i < selected.length; i++) {
                                                                                                                        if (selected[i].value === 'All') {
                                                                                                                            selected.splice(i, 1)
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                            return props.onChannelChangeFilter(selected, index, "Selected2")
                                                                                                        }}
                                                                                                        allowSelectAll={true}
                                                                                                        value={item.Selected2 ? item.Selected2 : null}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DragDropContext>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompActiveHeirarchyAndCampaignFilter