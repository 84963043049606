import React, { useState, useEffect } from "react";
// import PreviousOptimiseData from "./PreviousOptimiseData";
// import NewOptimiseData from "./NewOptimiseData";
// import EditNewOptimiseData from "./EditNewOptimiseData";
// import ChannelAllocation from "./ChannelAllocation";
// import GrowthDistributionChart from "./GrowthDistributionChart";
// import SpendsContributionChart from "./SpendsContributionChart";
// import Header from "./Header";
// import Footer from "./Footer";
// import axios from "../../axios"
import { useLocation } from "react-router-dom";

import ToggleButton from "react-toggle-button";

import axios from "../../axios";

import { Stack, Typography } from "@mui/material";

// import Download from '../../images/download-icon.png'
import TableData from "./TableData";
import Allocation from "./Allocation";
import SummaryGrowth from "./SummaryGrowth";

import Charts from "./Charts/Main";

import { Tooltip } from "react-tooltip";

const SkewbSimulator = (props) => {

  console.log("Re: ", props.Re);
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));

  const [charts, setCharts] = useState(true);

  const returnUnit = () => {
    if (props.optParameter.optimization_type === "budget") {
      return (props.optParameter.budget / 100).toFixed(2) + " INR Cr";
    } else {
      return props.optParameter.budget + " %";
    }
  };

  // ###################################################

  // const [isLoading, setIsLoading] = useState(false);

  // // const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  // // console.log("Analyse: ", Analyse);

  // const [allStates, setAllStates] = useState([]);
  // // console.log("allStates: ", allStates);
  // const [defaultState, setDefaultState] = useState("");

  // // console.log("defaultState: ", defaultState);

  // const [allBrands, setAllBrands] = useState([]);
  // // console.log("allBrands: ", allBrands);
  // const [defaultBrand, setDefaultBrand] = useState("");
  // // console.log("defaultBrand: ", defaultBrand);

  // const getBrandsByState = (state) => {
  //   // console.log("Calling getBrandsByState with state:", state);
  //   const selectedData = allStates.find((item) => item.value === state);
  //   // console.log("selectedData: ", selectedData);

  //   if (selectedData) {
  //     setAllBrands(selectedData.brands);
  //     setDefaultBrand(selectedData.brands[0].value);
  //   }
  // };

  // useEffect(() => {
  //   // console.log("Running useEffect for defaultState:", defaultState);
  //   getBrandsByState(defaultState);
  // }, [defaultState, allStates]);

  // useEffect(() => {
  //   // console.log("time period called")

  //   const fetchData = async () => {
  //     if (props.Re === 1) {
  //       try {
  //         const response = await axios.post("statelist/", {
  //           brand: Analyse.Brand,
  //           kpi: Analyse.KPI,
  //         });
  //         const data = await response.data; // Use await to get the data
  //         // console.log("solid gauge", data.data);
  //         setAllStates(data.data);
  //         setDefaultState(data.data[0].value);
  //         setAllBrands(data.data[0].brands);
  //         setDefaultBrand(data.data[0].brands[0].value);
  //         props.setRe(0);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [props.Re]);

  // // payload

  // const [clickedSeriesName, setClickedSeriesName] = useState("");
  // // console.log("clickedSeriesName: ", clickedSeriesName);

  // const [clickedCatName, setClickedCatName] = useState("");
  // // console.log("clickedCatName: ", clickedCatName);

  // const [chartdata, setChartdata] = useState({
  //   h0: {
  //     selection: [],
  //     Filter: [],
  //   },
  // });

  // // console.log("chartdata: ", chartdata);

  // // update chartdata
  // // useEffect(() => {
  // //   setChartdata({
  // //     h0: {
  // //       selection: [defaultState],
  // //       Filter: [defaultState],
  // //     },
  // //   });
  // // }, [defaultState]);

  // // Z Radar Chart
  // const [radarChartData, setRadarChartData] = useState([]);
  // // console.log("radarChartData: ", radarChartData);
  // const [radarTitle, setRadarTitle] = useState("");
  // // console.log("radarTitle: ", radarTitle);

  // useEffect(() => {
  //   // console.log("time period called")
  //   const fetchData = async () => {
  //     if (defaultState === "" && props.Re === 1) {
  //       try {
  //         setRadarChartData([]);

  //         setIsLoading(true);

  //         const response = await axios.post("zoomable/", {
  //           statels: [defaultState],
  //           brandls: [defaultBrand], // series name
  //           channells: [],
  //           brand: Analyse.Brand,
  //           kpi: Analyse.KPI,
  //           timeframe: "",
  //           startdate: "",
  //           enddate: "",
  //           is_all_enabled: 0,
  //           reset: 0,
  //           chartdata: chartdata,
  //           numericalfilter: [],
  //           theme: "Dark",
  //           opt_parameter: props.optParameter,
  //         });
  //         const data = await response.data; // Use await to get the data
  //         // console.log("radar", data.data);
  //         setRadarChartData(data.data.data);
  //         setRadarTitle(data.data.chart_title);
  //         setIsLoading(false);
  //         props.setRe(0);
  //       } catch (error) {
  //         setIsLoading(false);
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [Analyse.Brand, Analyse.KPI, defaultState, defaultBrand, props.Re]);

  // const [radialChartData, setRadialChartData] = useState([]);
  // // console.log("radialChartData: ", radialChartData);
  // const [radialTitle, setRadialTitle] = useState("");
  // // console.log("radialTitle: ", radialTitle);

  // useEffect(() => {
  //   // console.log("time period called")
  //   const fetchData = async () => {
  //     if (props.Re === 1) {
  //       try {
  //         setRadialChartData([]);

  //         const response = await axios.post("radial/", {
  //           statels: [defaultState],
  //           brandls: [defaultBrand], // series name
  //           channells: [],
  //           brand: Analyse.Brand,
  //           kpi: Analyse.KPI,
  //           timeframe: "",
  //           startdate: "",
  //           enddate: "",
  //           is_all_enabled: 0,
  //           reset: 0,
  //           chartdata: chartdata,
  //           numericalfilter: [],
  //           theme: "Dark",
  //           opt_parameter: props.optParameter,
  //         });
  //         const data = await response.data; // Use await to get the data
  //         // console.log("radial", data.data);
  //         setRadialChartData(data.data.data);
  //         setRadialTitle(data.data.chart_title);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [Analyse.Brand, Analyse.KPI, defaultState, defaultBrand, props.Re]);

  // const [solidGaugeData, setSolidGaugeData] = useState([]);
  // // console.log("solidGaugeData: ", solidGaugeData);
  // const [guageTitle, setGuageTitle] = useState("");
  // // console.log("guageTitle: ", guageTitle);

  // useEffect(() => {
  //   // console.log("time period called")
  //   const fetchData = async () => {
  //     if (defaultState !== "" && props.Re === 1) {
  //       try {
  //         setSolidGaugeData([]);

  //         setIsLoading(true);
  //         const response = await axios.post("solid/", {
  //           statels: [defaultState],
  //           brandls: [defaultBrand], // series name
  //           channells: [],
  //           brand: Analyse.Brand,
  //           kpi: Analyse.KPI,
  //           timeframe: "",
  //           startdate: "",
  //           enddate: "",
  //           is_all_enabled: 0,
  //           reset: 0,
  //           chartdata: chartdata,
  //           numericalfilter: [],
  //           theme: "Dark",
  //           opt_parameter: props.optParameter,
  //         });
  //         const data = await response.data; // Use await to get the data
  //         // console.log("solid gauge", data.data);
  //         setSolidGaugeData(data.data.data);
  //         setGuageTitle(data.data.chart_title);
  //         setIsLoading(false);
  //         props.setRe(0);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //         setIsLoading(false);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [Analyse.Brand, Analyse.KPI, defaultState, defaultBrand, props.Re]);

  // const [radarData, setRadarData] = useState({});
  // // console.log("radarData: ", radarData);
  // const [radarTimelineTitle, setRadarTimelineTitle] = useState("");
  // // console.log("radarTimelineTitle: ", radarTimelineTitle);

  // // console.log("radarData: ", radarData);

  // useEffect(() => {
  //   // console.log("time period called")
  //   const fetchData = async () => {
  //     if (props.Re === 1) {
  //       try {
  //         setRadarData([]);
  //         const response = await axios.post("radar/", {
  //           statels: [defaultState],
  //           brandls: [defaultBrand], // series name
  //           channells: [],
  //           brand: Analyse.Brand,
  //           kpi: Analyse.KPI,
  //           timeframe: "",
  //           startdate: "",
  //           enddate: "",
  //           is_all_enabled: 0,
  //           reset: 0,
  //           chartdata: chartdata,
  //           numericalfilter: [],
  //           theme: "Dark",
  //           opt_parameter: props.optParameter,
  //         });
  //         const data = await response.data; // Use await to get the data
  //         // console.log("radar gauge", data.data);
  //         setRadarData(data.data.data);
  //         setRadarTimelineTitle(data.data.chart_title);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [Analyse.Brand, Analyse.KPI, defaultState, defaultBrand, props.Re]);

  // const legends = [
  //   { name: "tajmahal", color: "#0000FF" },
  //   { name: "lipton", color: "#ffff00" },
  //   { name: "3roses", color: "#FF1493" },
  //   { name: "taaza", color: "#1fd655" },
  //   { name: "redlabel", color: "#ff0000" },
  // ];

  // const legends2 = [
  //   { name: "tv", color: "#d6ff41" },
  //   { name: "yt", color: "#1cf347" },
  //   { name: "meta", color: "#fa5d28" },
  //   { name: "ooh", color: "#ec4094" },
  //   { name: "ott", color: "#39c5f8" },
  // ];

  // const returnChartRadial = () => {
  //   if (defaultBrand !== "" && defaultState !== "") {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  // const returnChartZRadar = () => {
  //   if (defaultState === "") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // const returnChartGuage = () => {
  //   if (defaultState !== "" && defaultBrand === "") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // // console.log(
  // //   "returnChart: ",
  // //   returnChartZRadar(),
  // //   returnChartGuage(),
  // //   returnChartRadial()
  // // );

  // const returnFullPie = () => {
  //   if (
  //     returnChartZRadar() === false &&
  //     returnChartGuage() === false &&
  //     returnChartRadial() === false
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // console.log("returnFullPie: ", returnFullPie());

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Skewb Simulator */}
                    {Analyse && Analyse.Menubar
                      ? Analyse.Menubar.map((item) => {
                          return "/" + item.key === location.pathname
                            ? item.value +
                                "- Optimized for " +
                                props.optParameter.optimization_type +
                                " " +
                                returnUnit()
                            : null;
                        })
                      : null}
                  </h1>

                  <Stack direction="row" gap={1} justifyContent={"flex-end"}>
                    <Typography
                      sx={{
                        color: !charts ? "#d6ff41" : "white",
                        fontSize: "0.85rem",
                        // opacity: !props.toggle ? 1 : 0.3,
                      }}
                    >
                      Deep Dive
                    </Typography>
                    <ToggleButton
                      value={charts}
                      inactiveLabel=""
                      activeLabel=""
                      onToggle={(value) => {
                        setCharts(!value);
                      }}
                      colors={{
                        activeThumb: {
                          base: "#d6ff41",
                        },
                        inactiveThumb: {
                          base: "#d6ff41",
                        },
                        active: {
                          base: "#3E5056",
                          // hover: "rgb(177, 191, 215)",
                        },
                        inactive: {
                          base: "#3E5056",
                          // hover: "rgb(95,96,98)",
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        color: charts ? "#d6ff41" : "white",
                        fontSize: "0.85rem",
                        // opacity: props.toggle ? 1 : 0.3,
                      }}
                    >
                      Summary
                    </Typography>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container-fluid main-content-w simulator"
            // style="padding-top: 25px; max-width: 99% !important;"
            // style={{ maxWidth: "99%", padding: "25px 25px 0px 25px" }}
          >
            {charts ? (
              <Charts
                optParameter={props.optParameter}
                Re={props.Re}
                setRe={props.setRe}
                // isLoading={isLoading}

                // allStates={allStates}
                // defaultState={defaultState}
                // setDefaultState={setDefaultState}

                // allBrands={allBrands}
                // defaultBrand={defaultBrand}
                // setDefaultBrand={setDefaultBrand}

                // radarChartData={radarChartData}
                // radarTitle={radarTitle}

                // solidGaugeData={solidGaugeData}
                // guageTitle={guageTitle}

                // radialChartData={radialChartData}
                // radialTitle={radialTitle}

                // radarData={radarData}
                // radarTimelineTitle={radarTimelineTitle}

                // returnFullPie={returnFullPie}

                // legends={legends}
                // legends2={legends2}

                // returnChartGuage={returnChartGuage}
                // returnChartRadial={returnChartRadial}
                // returnChartZRadar={returnChartZRadar}
                
              />
            ) : (
              <div className="row">
                <TableData
                  YearHandler={props.YearHandler}
                  PreviousData={props.PreviousData}
                  SelectedYear={props.SelectedYear}
                  NewData={props.NewData}
                  NewFixedData={props.NewFixedData}
                  AllowEdit={props.AllowEdit}
                  setAllowEdit={props.setAllowEdit}
                  Summary={props.Summary}
                  EditNewSpends={props.EditNewSpends}
                  EditNewSpendsOnBlur={props.EditNewSpendsOnBlur}
                  ResetSpends={props.ResetSpends}
                  setSaveResult={props.setSaveResult}
                  Optimise={props.Optimise}
                  ChannelExpand={props.ChannelExpand}
                  ChannelExpand1={props.ChannelExpand1}
                  handleShrinkChannel={props.handleShrinkChannel}
                  handleExpandChannel={props.handleExpandChannel}
                  handleShrinkChannel1={props.handleShrinkChannel1}
                  handleExpandChannel1={props.handleExpandChannel1}
                  Levels={props.Levels}
                  AddLevel={props.AddLevel}
                  RemoveLevel={props.RemoveLevel}
                  traverse={props.traverse}
                  DownloadOptimizer={props.DownloadOptimizer}
                  flattened={props.flattened}
                  setRe={props.setRe}
                  setIsExecuted={props.setIsExecuted}
                  setUserInputKey={props.setUserInputKey}
                />

                <Allocation
                  AllowEdit={props.AllowEdit}
                  SuggestedAllocation={props.SuggestedAllocation}
                  EditedAllocation={props.EditedAllocation}
                />

                <SummaryGrowth
                  AllowEdit={props.AllowEdit}
                  OptSummarydict={props.OptSummarydict}
                  OptChartdict={props.OptChartdict}
                  setChartDataMain={props.setChartDataMain}
                />
              </div>
            )}
          </div>
          <div className="row custom-note">
            <div className="col-sm-1">
              {props.Notes !== "" ? (
                <>
                  <i
                    className="fas fa-mail-bulk chart_info_icon footer-notes-icon"
                    id="clickable"
                  ></i>
                  <Tooltip anchorSelect="#clickable" clickable>
                    {/* <div className="footer-notes">
                          {Notes}
                        </div> */}
                    <div
                      className="footer-notes"
                      dangerouslySetInnerHTML={{ __html: props.Notes }}
                    ></div>
                  </Tooltip>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SkewbSimulator;
