import React, { useState } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
// import NetworkChart from "./NetworkChart";
import { Box, Stack } from "@mui/material";
// import SankeyContainer from "./SankeyChart";
import SankeyContainer from "./SankeyContainer";

const Tab = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div>
      <Stack
        direction={"row"}
        justifyContent={"start"}
        gap={4}
        sx={{
          backgroundColor: "#1c2427",
          padding: "1rem",
          borderRadius: "20px 20px 0 0",
        }}
      >
        {tabs.map((tab, index) => (
          <Box
            key={index}
            onClick={() => setActiveTab(index)}
            sx={{
              cursor: "pointer",
              color: activeTab === index ? "#d6ff41" : "white",
              borderBottom: activeTab === index ? "1px solid #d6ff41" : "",
            }}
          >
            {tab.label}
          </Box>
        ))}
      </Stack>
      <div>{tabs[activeTab].content}</div>
    </div>
  );
};

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    // {
    //   label: "KPI Prioritization",
    //   content: <NetworkChart />,
    // },
    {
      label: "Sankey Chart",
      content: <SankeyContainer />,
    },
  ];

  return <Tab tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />;
};

const Main = () => {
  return (
    <>
      <Topbar />
      <Menubar />
      <div className="main-content" style={{ width: "100%" }}>
        <div className="page-content">
          <Tabs />
        </div>
      </div>
    </>
  );
};

export default Main;
