import { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5flow from "@amcharts/amcharts5/flow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const SankeyChart = ({ data, names }) => {
  const [id, setId] = useState("");
  const [tooltipNodes, setTooltipNodes] = useState([]);

  // console.log("id: ", id);
  // console.log("tooltipNodes: ", tooltipNodes);

  // console.log("data: ", data);

  useEffect(() => {
    const output = data.filter((node) => {
      return node.id === id;
    });

    setTooltipNodes(output);
  }, [id, data]);

  useEffect(() => {
    // Create root element
    const root = am5.Root.new("chartdiv100");

    root._logo.dispose();

    // Create theme instance
    const theme = am5themes_Animated.new(root);

    // Set themes
    root.setThemes([theme]);

    // Create series
    const series = root.container.children.push(
      am5flow.Sankey.new(root, {
        sourceIdField: "from",
        targetIdField: "to",
        valueField: "value",
        paddingRight: 50,
        idField: "id",
        nodeWidth: 15,
      })
    );

    series.nodes.rectangles.template.setAll({
      fillOpacity: 1,
    });

    series.links.template.setAll({
      fillOpacity: 0.3,
    });

    series.nodes.setAll({
      idField: "id",
      nameField: "name",
    });

    series.nodes.data.setAll(names);

    let tooltip = am5.Tooltip.new(root, {
      getLabelFillFromSprite: true,
      // html: "<div>\n     </div><div> list 1 </div> \n<div> list 2 </div>",
      labelText: "[bold]{fromName} → {toName}: {value} {units}[/]",
    });

    series.set("tooltip", tooltip);

    series.links.template.setAll({ strokeOpacity: 0, strokeWidth: 0 });

    // highlight all links with the same id beginning
    series.links.template.events.on("pointerover", function (event) {
      const dataItem = event.target.dataItem;
      // console.log("dataItem: ", dataItem.dataContext);
      setId(dataItem.dataContext.id);
      const id = dataItem.get("id").split("-")[0];
      dataItem.get("link").fillOpacity = 1; // Set desired opacity on hover

      am5.array.each(series.dataItems, function (dataItem) {
        if (dataItem.get("id").indexOf(id) !== -1) {
          dataItem.get("link").hover();
        }
      });
    });

    series.links.template.events.on("pointerout", function (event) {
      am5.array.each(series.dataItems, function (dataItem) {
        dataItem.get("link").unhover();
        dataItem.get("link").fillOpacity = 0.3; // Set original opacity when pointer moves away
      });
    });

    series.data.setAll(data);
    // Make stuff animate on load
    series.appear(1000, 100);

    // Clean up
    return () => {
      root.dispose();
    };
  }, [data, names]);

  return <div id="chartdiv100" style={{ width: "100%", height: "100%" }} />;
};

export default SankeyChart;
