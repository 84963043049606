import { useState, useEffect } from "react";
import { Stack } from "@mui/material";

import SankeyChart from "./Sankey2";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

// import axios from "axios";
import axios from "../../axios";
import Loader from "../.././Components/Loader";

const SankeyContainer = () => {
  const [isFullWidth, setIsFullWidth] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [names, setNames] = useState([]);

  const Analyse = JSON.parse(localStorage.getItem("Analyse"));

  // console.log("data: ", data);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.post("sankey_json/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
        });
        const data = response.data;
        // console.log(data);
        setData(data.data.data);
        setNames(data.data.names);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : null}
      <Stack
        sx={{
          backgroundColor: "#1c2427",
          width: isFullWidth ? "100%" : "50%",
          padding: "1rem 2rem",
          height: "600px",
          borderRadius: "0 0 20px 20px",
        }}
      >
        {/* {!isFullWidth ? (
          <FullscreenIcon
            onClick={() => setIsFullWidth(!isFullWidth)}
            sx={{ cursor: "pointer" }}
          />
        ) : (
          <FullscreenExitIcon
            onClick={() => setIsFullWidth(!isFullWidth)}
            sx={{ cursor: "pointer" }}
          />
        )} */}
        {data?.length > 0 && <SankeyChart data={data} names={names} />}
      </Stack>
    </>
  );
};

export default SankeyContainer;
