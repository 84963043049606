import { useState, useEffect } from "react";
import axios from "../../../axios";
import { Stack, Grid, Button, Typography, Box } from "@mui/material";

import SelectState from "./SelectState";

// import Topbar from "../../Components/Topbar";
// import Menubar from "../../Components/Menubar";

// charts
import ZRadar from "./ZRadar";
import RHistogram from "./RHistogram";
import SolidGauge from "./SolidGuage";
// import RTimeline from "./RTimeline";
import PieChart from "./PieChart";

import Loader from "../../../Components/Loader";

const Main = (props) => {
  // console.log("Re: ", props.Re);
  const [isLoading, setIsLoading] = useState(false);

  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  // console.log("Analyse: ", Analyse);

  const [allStates, setAllStates] = useState([]);
  // console.log("allStates: ", allStates);
  const [defaultState, setDefaultState] = useState("");

  //   console.log("defaultState: ", defaultState);

  const [allBrands, setAllBrands] = useState([]);
  // console.log("allBrands: ", allBrands);
  const [defaultBrand, setDefaultBrand] = useState("");
  //   console.log("defaultBrand: ", defaultBrand);

  const [isSubmit, setIsSubmit] = useState(true);

  const [dropdown, setDropdown] = useState(false);

  const getBrandsByState = (state) => {
    // console.log("Calling getBrandsByState with state:", state);
    const selectedData = allStates.find((item) => item.value === state);
    // console.log("selectedData: ", selectedData);

    if (selectedData) {
      setAllBrands(selectedData.brands);
      setDefaultBrand(selectedData.brands[0].value);
    }
  };

  useEffect(() => {
    // console.log("Running useEffect for defaultState:", defaultState);
    getBrandsByState(defaultState);
  }, [defaultState, allStates]);

  useEffect(() => {
    // console.log("time period called")

    const fetchData = async () => {
      try {
        const response = await axios.post("statelist/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
        });
        const data = await response.data; // Use await to get the data
        // console.log("solid gauge", data.data);
        setAllStates(data.data);
        setAllBrands(data.data[0].brands);

        setDefaultState(data.data[0].value);
        setDefaultBrand(data.data[0].brands[0].value);
        setDropdown(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // payload

  const [clickedSeriesName, setClickedSeriesName] = useState("");
  // console.log("clickedSeriesName: ", clickedSeriesName);

  const [clickedCatName, setClickedCatName] = useState("");
  // console.log("clickedCatName: ", clickedCatName);

  const [chartdata, setChartdata] = useState({
    h0: {
      selection: [],
      Filter: [],
    },
  });

  // console.log("chartdata: ", chartdata);

  // update chartdata
  // useEffect(() => {
  //   setChartdata({
  //     h0: {
  //       selection: [defaultState],
  //       Filter: [defaultState],
  //     },
  //   });
  // }, [defaultState]);

  // Z Radar Chart

  const [isZRadarVisible, setIsZRadarVisible] = useState("");
  //   console.log("isZRadarVisible: ", isZRadarVisible);
  const [isSolidGuageVisible, setIsSolidGuageVisible] = useState("");
  //   console.log("isSolidGuageVisible: ", isSolidGuageVisible);
  const [isRHistogramVisible, setIsRHistogramVisible] = useState("");
  //   console.log("isRHistogramVisible: ", isRHistogramVisible);
  const [isPieChartVisible, setIsPieChartVisible] = useState("");
  //   console.log("isPieChartVisible: ", isPieChartVisible);

  //   chart visible

  const [radarChartData, setRadarChartData] = useState([]);
  // console.log("radarChartData: ", radarChartData);
  const [radarTitle, setRadarTitle] = useState("");
  // console.log("radarTitle: ", radarTitle);

  useEffect(() => {
    // console.log("time period called")
    const fetchData = async () => {
      if (isSubmit && dropdown) {
        try {
          setRadarChartData([]);
          setIsLoading(true);
          setRadarTitle("");
          const response = await axios.post("zoomable/", {
            statels: [defaultState],
            brandls: [defaultBrand], // series name
            channells: [],
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            timeframe: "",
            startdate: "",
            enddate: "",
            is_all_enabled: 0,
            reset: 0,
            chartdata: chartdata,
            numericalfilter: [],
            theme: "Dark",
            opt_parameter: props.optParameter,
          });
          const data = await response.data; // Use await to get the data
          // console.log("radar", data.data);
          setRadarChartData(data.data.data);
          setIsZRadarVisible(data.data.show_flag);
          setRadarTitle(data.data.chart_title);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [
    Analyse.Brand,
    Analyse.KPI,
    defaultState,
    defaultBrand,
    isSubmit,
    dropdown,
  ]);

  const [radialChartData, setRadialChartData] = useState([]);
  // console.log("radialChartData: ", radialChartData);
  const [radialTitle, setRadialTitle] = useState("");
  // console.log("radialTitle: ", radialTitle);

  useEffect(() => {
    // console.log("time period called")
    const fetchData = async () => {
      if (isSubmit && dropdown) {
        try {
          setRadialChartData([]);
          setRadialTitle("");
          const response = await axios.post("radial/", {
            statels: [defaultState],
            brandls: [defaultBrand], // series name
            channells: [],
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            timeframe: "",
            startdate: "",
            enddate: "",
            is_all_enabled: 0,
            reset: 0,
            chartdata: chartdata,
            numericalfilter: [],
            theme: "Dark",
            opt_parameter: props.optParameter,
          });
          const data = await response.data; // Use await to get the data
          // console.log("radial", data.data);
          setRadialChartData(data.data.data);
          setIsRHistogramVisible(data.data.show_flag);
          setRadialTitle(data.data.chart_title);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [
    Analyse.Brand,
    Analyse.KPI,
    defaultState,
    defaultBrand,
    isSubmit,
    dropdown,
  ]);

  const [solidGaugeData, setSolidGaugeData] = useState([]);
  // console.log("solidGaugeData: ", solidGaugeData);
  const [guageTitle, setGuageTitle] = useState("");
  // console.log("guageTitle: ", guageTitle);

  useEffect(() => {
    // console.log("time period called")
    const fetchData = async () => {
      if (isSubmit && dropdown) {
        try {
          setSolidGaugeData([]);
          setGuageTitle("");
          setIsLoading(true);
          const response = await axios.post("solid/", {
            statels: [defaultState],
            brandls: [defaultBrand], // series name
            channells: [],
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            timeframe: "",
            startdate: "",
            enddate: "",
            is_all_enabled: 0,
            reset: 0,
            chartdata: chartdata,
            numericalfilter: [],
            theme: "Dark",
            opt_parameter: props.optParameter,
          });
          const data = await response.data; // Use await to get the data
          // console.log("solid gauge", data.data);
          setSolidGaugeData(data.data.data);
          setIsSolidGuageVisible(data.data.show_flag);
          setGuageTitle(data.data.chart_title);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [
    Analyse.Brand,
    Analyse.KPI,
    defaultState,
    defaultBrand,
    isSubmit,
    dropdown,
  ]);

  const [radarData, setRadarData] = useState({});
  console.log("radarData: ", radarData);
  const [radarTimelineTitle, setRadarTimelineTitle] = useState("");
  // console.log("radarTimelineTitle: ", radarTimelineTitle);

  const [radarError, setRadarError] = useState(0);
  console.log("radarError: ", radarError);

  // console.log("radarData: ", radarData);

  useEffect(() => {
    // console.log("time period called")
    const fetchData = async () => {
      if (isSubmit && dropdown) {
        try {
          setIsLoading(true);
          setRadarData([]);
          setRadarError("");
          setRadarTimelineTitle("");
          const response = await axios.post("radar/", {
            statels: [defaultState],
            brandls: [defaultBrand], // series name
            channells: [],
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            timeframe: "",
            startdate: "",
            enddate: "",
            is_all_enabled: 0,
            reset: 0,
            chartdata: chartdata,
            numericalfilter: [],
            theme: "Dark",
            opt_parameter: props.optParameter,
          });
          const data = await response.data; // Use await to get the data
          // console.log("radar gauge", data.data);
          setRadarError(response.data.error);
          setRadarData(data.data.data);
          setIsPieChartVisible(data.data.show_flag);
          setRadarTimelineTitle(data.data.chart_title);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [
    Analyse.Brand,
    Analyse.KPI,
    defaultState,
    defaultBrand,
    isSubmit,
    dropdown,
  ]);

  const legends = [
    { name: "tajmahal", color: "#0000FF" },
    { name: "lipton", color: "#ffff00" },
    { name: "3roses", color: "#FF1493" },
    { name: "taaza", color: "#1fd655" },
    { name: "redlabel", color: "#ff0000" },
  ];

  const legends2 = [
    { name: "tv", color: "#d6ff41" },
    { name: "yt", color: "#1cf347" },
    { name: "meta", color: "#fa5d28" },
    { name: "ooh", color: "#ec4094" },
    { name: "ott", color: "#39c5f8" },
  ];

  const returnFullPie = () => {
    if (
      isZRadarVisible === 0 &&
      isSolidGuageVisible === 0 &&
      isRHistogramVisible === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  console.log("returnFullPie: ", returnFullPie());

  return (
    <>
      {isLoading ? <Loader /> : null}

      <Stack
        position={"relative"}
        sx={{
          backgroundColor: "#1c2427",
          borderRadius: "20px",
          padding: "1rem",
          height: "650px",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "12px",
          },

          "&::-webkit-scrollbar-track": {
            background: "#1C2427",
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: "10px",
          },
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} gap={2}>
            <SelectState
              allStates={allStates}
              defaultState={defaultState}
              setDefaultState={setDefaultState}
              setIsSubmit={setIsSubmit}
            />

            <SelectState
              allStates={allBrands}
              defaultState={defaultBrand}
              setDefaultState={setDefaultBrand}
              setIsSubmit={setIsSubmit}
            />

            <Button
              onClick={() => setIsSubmit(true)}
              sx={{
                width: 90,
                padding: 0,
                height: 25,
                fontSize: "0.75rem",
                backgroundColor: "#1c2427",
                color: "#d6ff41",
                border: "1px solid #d6ff41",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "#d6ff41",
                  color: "black",
                },
              }}
            >
              submit
            </Button>
          </Stack>
          <Stack direction={"row"} gap={5}>
            {legends.map((legend) => {
              return (
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <Typography variant="h6" sx={{ color: "white" }}>
                    {legend.name}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: legend.color,
                      height: 15,
                      width: 15,
                      border: "1px solid white",
                    }}
                  ></Box>
                </Stack>
              );
            })}
          </Stack>
          {/* <Typography sx={{ color: "white" }}>*All spends in INR Cr</Typography> */}
        </Stack>

        <Grid container marginTop={5}>
          {isZRadarVisible ? (
            <Grid item xs={3}>
              <Stack alignItems={"center"}>
                <Typography sx={{ color: "white" }}>{radarTitle}</Typography>
                {radarChartData?.length > 0 ? (
                  <ZRadar
                    radarChartData={radarChartData}
                    setClickedCatName={setClickedCatName}
                    setClickedSeriesName={setClickedSeriesName}
                  />
                ) : null}
              </Stack>
            </Grid>
          ) : null}
          {isSolidGuageVisible ? (
            <Grid item xs={3}>
              <Stack alignItems={"center"}>
                <Typography sx={{ color: "white" }}>{guageTitle}</Typography>
                {solidGaugeData.length > 0 ? (
                  <SolidGauge
                    solidGaugeData={solidGaugeData}
                    setClickedCatName={setClickedCatName}
                  />
                ) : null}
              </Stack>
            </Grid>
          ) : null}

          {isRHistogramVisible ? (
            <Grid item xs={3}>
              <Stack alignItems={"center"}>
                <Typography sx={{ color: "white" }}>{radialTitle}</Typography>
                {radialChartData.length > 0 ? (
                  <RHistogram
                    data={radialChartData}
                    setClickedCatName={setClickedCatName}
                  />
                ) : null}
              </Stack>
            </Grid>
          ) : null}
          {isPieChartVisible ? (
            <Grid item xs={returnFullPie() ? 12 : 6}>
              <Stack alignItems={"center"}>
                <Typography sx={{ color: "white" }}>
                  {radarTimelineTitle}
                </Typography>
                {radarData.length > 0 ? (
                  <PieChart radarData={radarData} />
                ) : null}
              </Stack>
            </Grid>
          ) : null}

          {radarError ? (
            <Grid item xs={12}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  height: "250px",
                  width: "30%",
                  margin: "auto",
                  borderRadius: "1rem",
                  backgroundColor: "#000000",
                }}
              >
                <Typography variant="h5" sx={{ color: "#d6ff41" }}>
                  {"No Spends Allocated"}
                </Typography>
              </Stack>
            </Grid>
          ) : null}
        </Grid>
        <Stack
          direction={"row"}
          gap={5}
          position={"absolute"}
          bottom={0}
          right={0}
        >
          {legends2.map((legend) => {
            return (
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <Typography variant="h6" sx={{ color: "white" }}>
                  {legend.name}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: legend.color,
                    height: 15,
                    width: 15,
                    border: "1px solid white",
                  }}
                ></Box>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};

export default Main;
