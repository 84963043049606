import React from "react";
import Cascader from "rc-cascader";

const options = [
  {
    label: "Fujian",
    value: "fj",
    children: [
      {
        label: "Fuzhou",
        value: "fuzhou",
        children: [
          {
            label: "Mawei",
            value: "mawei",
          },
        ],
      },
      {
        label: "Quanzhou",
        value: "quanzhou",
      },
    ],
  },
  {
    label: "Zhejiang",
    value: "zj",
    children: [
      {
        label: "Hangzhou",
        value: "hangzhou",
        children: [
          {
            label: "Yuhang",
            value: "yuhang",
          },
        ],
      },
    ],
  },
  {
    label: "Beijing",
    value: "bj",
    children: [
      {
        label: "Chaoyang District",
        value: "chaoyang",
      },
      {
        label: "Haidian District",
        value: "haidian",
      },
    ],
  },
];

const Testing = () => {
  const cascaderStyles = {
    width: 200,
    backgroundColor: "lightgray",
    padding: "10px",
    borderRadius: "5px",
  };

  return <Cascader options={options} style={cascaderStyles} />;
};

export default Testing;
